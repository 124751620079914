$(document).ready(function () {

    var $afsDropdownFilter = $('.afs-dropdown-filter');

    $.each($afsDropdownFilter, function(index, currentDropDownFilter) {

        var $currentDropdownFilter = $(currentDropDownFilter);
        var $currentSelectSelected = $currentDropdownFilter.find('.select-selected');
        var $currentSelectItems = $currentDropdownFilter.find('.select-items');

        $currentSelectItems.on('click', function () {
            $currentSelectSelected.css('font-weight','bold');
        })

    })
});